import wallet_account_alipay_fundBatch_detail from "@/lib/data-service/default/wallet_account_alipay_fundBatch_detail";
import wallet_account_alipay_fundBatch_reAccountList from '@/lib/data-service/default/wallet_account_alipay_fundBatch_reAccountList';
import wallet_order_alipay_fundBatch_recharge from '@/lib/data-service/default/wallet_order_alipay_fundBatch_recharge';
import wallet_account_alipay_fundBatch_companyAccountList from "@/lib/data-service/default/wallet_account_alipay_fundBatch_companyAccountList";
import wallet_order_alipay_fundBatch_recharge_cancel from "@/lib/data-service/default/wallet_order_alipay_fundBatch_recharge_cancel";

export default {
    data() {
        const validatorAmount = (rule, value, callback) => {
            let re = /(^[1-9](\d+)?(\.\d{1,2})?$)|(^\d\.\d{1,2}$)/
            if (re.test(value)) {
                if (Number(value) < 1) {
                    callback(new Error(`转账金额必须大于 1`));
                } else {
                    callback();
                }
            } else {
                callback(new Error('请输入大于0且最多可带两位小数'))
            }
        }
        return {
            loading: false,
            formData: {
                id: '',
                accountId: '',
                companyId: '',
                reAccountName: '',
                reAccountNo: '',
                dsAccountNo: '',
                amount: null,
            },
            dsAccountList: [],
            reAccountList: [],
            rules: {
                reAccountNo: [{required: true, message: '请输入收款账号', trigger: 'blur'}],
                dsAccountNo: [{required: true, message: '请输入付款账号', trigger: 'blur'}],
                amount: [
                    {required: true, message: '请输入转账金额', trigger: 'blur'},
                    {validator: validatorAmount, trigger: 'blur'},
                ],
            }
        };
    },
    mounted() {
    },
    activated() {
        this.getAccountDetail();
        this.alipayReAccountList();
        this.alipayDsAccountList();
    },
    methods: {
        getAccountDetail(){
            wallet_account_alipay_fundBatch_detail({}).then(res => {
                this.formData = res.accountResult
                this.formData.reAccountName = '广东胤之旅科技有限公司'
            }).catch(err => {
            })
        },
        alipayReAccountList(){
            wallet_account_alipay_fundBatch_reAccountList({}).then(res => {
                this.reAccountList = res.accountList
            }).catch(err => {
            })
        },
        alipayDsAccountList(){
            let params = {
                pageSize: 999,
                currentPage: 1,
                status: 2,
            }
            wallet_account_alipay_fundBatch_companyAccountList(params).then(res => {
                let list = []
                this.formData.dsAccountNo = res.pageResult.pageData[0].dsAccountNo;
                res.pageResult.pageData.forEach(item => {
                    if (item && item.status === 2 && item.dsAccountNo !== null && item.dsAccountNo !== '') {
                        list.push(item.dsAccountNo)
                    }
                })
                this.dsAccountList = list;
            }).catch(err => {
            })
        },
        changeReAccount(reAccountNo) {
            this.formData.reAccountNo = reAccountNo;
        },
        changeDsAccount(dsAccountNo) {
            this.formData.dsAccountNo = dsAccountNo;
            this.$forceUpdate();
        },
        //返回
        goBack() {
            this.$router.replace({
                name: 'distributor-alipay-account-management'
            });
        },
        //提交
        async submit() {
            this.$refs["form"].validate(valid => {
                if (valid) {
                    this.loading = true;
                    wallet_order_alipay_fundBatch_recharge(this.formData).then(res => {
                        this.loading = false;
                        //询问是否完成支付，取消支付主动取消订单
                        this.$confirm('是否完成支付操作?', '提示', {
                            confirmButtonText: '完成',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(() => {
                            this.$message({type: 'success', message: '完成支付！'})
                            this.$router.push({
                                name: 'distributor-alipay-account-management',
                            });
                        }).catch(() => {
                            let params = {
                                orderNo: orderNo
                            }
                            wallet_order_alipay_fundBatch_recharge_cancel(params).then(res => {
                                this.$router.push({
                                    name: 'distributor-alipay-account-management',
                                });
                            }).catch(err => {
                            })
                        });
                        var neww = window.open('', "_blank");
                        if (neww) {
                            var doc = neww.document;
                            doc.open();
                            doc.write(res.payFormHtml);
                            doc.close();
                        }
                    }).catch(err => {
                        this.loading = false;
                        $.messager.alert("错误信息", res.msg);
                    })
                }
            })
        },
        //跳转至自动上账记录列表
        toList() {
            this.$router.push({
                name: 'distributor-alipay-recharge-order-list',
                query: {
                    paymentChannel: 31,
                }
            });
        }
    }
};